<template>
  <div>
    <v-card class="mb-3 contact-filter">
      <v-card-actions
        >Filter

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            <v-autocomplete
              v-model="selectedTemplate"
              :items="templates_list"
              :loading="isLoadingTemplates"
              :search-input.sync="searchTemplates"
              @focus="() => onLoadTemplates()"
              item-text="name"
              item-value="id"
              label="Template"
              placeholder="Search"
              clearable
              return-object
            ></v-autocomplete>
            <div id="query_builder"></div>
          </v-card-text>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <v-btn class="ma-2" color="primary" @click="onExportClick">
                Apply
              </v-btn>
              <v-btn class="ma-2" color="info" outlined @click="onClearFilter">
                Clear
              </v-btn>
            </div>
            <div>
              <v-btn
                class="ma-2"
                color="info"
                @click="toggleOpenCreateTemplate"
              >
                Save as Template
              </v-btn>
              <v-btn
                class="ma-2"
                color="info"
                outlined
                @click="isOpenConfirm = true"
                :disabled="!selectedTemplate || !selectedTemplate.id"
              >
                Update Template
              </v-btn>
              <v-btn
                color="primary"
                class="ma-2"
                @click="toggleOpenCreateBroadcast"
                :disabled="isChangedFilter"
                :title="isChangedFilter ? 'Необходимо принять изменения' : ''"
              >
                Create Broadcast
              </v-btn>
            </div>
          </div>
        </div>
      </v-expand-transition>
    </v-card>
    <v-dialog :value="isOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Template</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Enter template name"
            v-model="templateName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="toggleOpenCreateTemplate">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="button"
            @click="onSaveTemplateClick"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="isOpenConfirm" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Please Confirm</span>
        </v-card-title>
        <v-card-text>
          <div v-if="selectedTemplate">
            Do you really want to update "{{ selectedTemplate.name }}"?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            type="button"
            @click="isOpenConfirm = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="button"
            @click="onConfirmUpdateTemplate"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { Builder } from "@/services/elasticsearch";
import {
  SET_FILTER,
  LOAD_RULES,
  CREATE_TEMPLATE,
  LOAD_TEMPLATES,
  UPDATE_TEMPLATE,
} from "../../../store/modules/contacts/types";
import { requestStatus } from "@/services/services";
import _ from "lodash";
export default {
  name: "ContactFilter",
  props: { toggleOpenCreateBroadcast: Function },
  data: () => ({
    show: true,
    isChangedFilter: false,
    templateName: "",
    selectedTemplate: null,
    searchTemplates: "",
    isOpen: false,
    isOpenConfirm: false,
    isLoadingTemplates: false,
  }),
  computed: {
    ...mapState({
      rules: (state) => state.contacts.rules,
      filterTemplates: (state) => state.contacts.filterTemplates,
      status: (state) => state.contacts.status,
      filter: (state) => state.contacts.filter,
    }),

    templates_list: function () {
      let results = [...this.filterTemplates];
      if (this.selectedTemplate) {
        let default_item = results.filter(
          (item) => this.selectedTemplate.id === item.id
        );
        if (!default_item.length) {
          results.push(this.selectedTemplate);
        }
      }
      return results;
    },
  },
  methods: {
    ...mapActions({
      onLoadRules: `contacts/${LOAD_RULES}`,
      onSetTemplates: `contacts/${CREATE_TEMPLATE}`,
      onLoadTemplates: `contacts/${LOAD_TEMPLATES}`,
      onCreateTemplate: `contacts/${CREATE_TEMPLATE}`,
      onUpdateTemplate: `contacts/${UPDATE_TEMPLATE}`,
    }),
    ...mapMutations({
      onSetFilter: `contacts/${SET_FILTER}`,
    }),
    onExportClick() {
      const filter = this.builder.getRules();
      localStorage.setItem("filter_conditions", JSON.stringify(filter));
      if (filter) {
        this.onSetFilter(filter);
        this.isChangedFilter = false;
      }
    },
    onClearFilter() {
      const filter = "";
      localStorage.setItem("filter_conditions", JSON.stringify(filter));
      this.onSetFilter(filter);
      this.builder.resetRules();
      this.isChangedFilter = false;
    },
    onSelectTemplatesClick() {
      this.onLoadTemplates();
    },
    onSaveTemplateClick() {
      const filter = this.builder.getRules();
      if (filter && this.templateName.length) {
        this.onCreateTemplate({
          name: this.templateName,
          filter_conditions: filter,
        });
        this.toggleOpenCreateTemplate();
      }
    },
    onWaitChangeTemplates() {
      if (
        this.searchTemplates &&
        (this.searchTemplates.length >= 2 || this.searchTemplates === "")
      ) {
        this.onLoadTemplates(`search=${this.searchTemplates}`);
      }
      if (localStorage.getItem("filter_conditions")) {
        this.isChangedFilter = false;
      }
    },
    onChangeRules() {
      this.isChangedFilter = true;
    },
    toggleOpenCreateTemplate() {
      this.templateName = "";
      this.isOpen = !this.isOpen;
    },
    onConfirmUpdateTemplate() {
      const filter = this.builder.getRules();
      this.onUpdateTemplate({
        id: this.selectedTemplate.id,
        data: { filter_conditions: filter, name: this.selectedTemplate.name },
      });
    },
  },
  watch: {
    rules(newValue) {
      if (newValue && newValue.length) {
        this.builder = new Builder(
          `#query_builder`,
          newValue,
          this.onChangeRules,
          this.filter
        );
        this.isChangedFilter = true;
      }
    },
    status(newValue) {
      if (
        newValue.change_field === "create_template" &&
        newValue.create_template === requestStatus.success
      ) {
        this.$toaster.success("Success create template");
      }
      if (
        newValue.change_field === "update_template" &&
        newValue.update_template === requestStatus.success
      ) {
        this.isOpenConfirm = false;
        this.$toaster.success("Success update template");
      }

      this.isLoadingTemplates =
        newValue.load_templates === requestStatus.loading;
    },
    selectedTemplate(newValue) {
      newValue && this.builder.setRules(newValue.filter_conditions);
    },
    searchTemplates(newValue) {
      if (!this.selectedTemplate || newValue !== this.selectedTemplate.name) {
        this.onWaitChangeTemplates();
      }
    },
  },
  created() {
    this.onChangeRules = this.onChangeRules.bind(this);
    this.onLoadRules();
    this.onWaitChangeTemplates = _.debounce(this.onWaitChangeTemplates, 500);
  },
};
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/npm/jQuery-QueryBuilder/dist/css/query-builder.default.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.7.1/css/bootstrap-datepicker.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/css/bootstrap-datetimepicker.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/selectize.js/0.13.3/css/selectize.bootstrap4.min.css";
</style>
