<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Broadcast</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name *"
                v-model="name"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="base_url"
                label="Base URL *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="country"
                :items="countries"
                :loading="isLoadingCountry"
                :search-input.sync="searchCountry"
                label="Country"
                placeholder="Search"
                item-text="name"
                item-value="id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="BROADCAST_TARGETS"
                label="Target"
                item-text="name"
                item-value="value"
                v-model="broadcast_target"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="offer"
                :items="offers"
                :loading="isLoadingOffer"
                :search-input.sync="searchOffer"
                label="Offer"
                placeholder="Search"
                item-text="name"
                item-value="id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="networkList"
                label="Network"
                v-model="network"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <tags ref="tags" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="max_links"
                label="Max Links"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="life_days"
                label="Life days"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="broadcast_type"
                :label="broadcast_type ? 'auto broadcast' : 'manual broadcast'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="toggleOpenModal">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="onCreateClick"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  CREATE_BROADCAST,
  LOAD_COUNTRIES,
  LOAD_OFFERS,
} from "../../../store/modules/broadcasts/types";
import { requestStatus, BROADCAST_TARGETS } from "@/services/services";

import Tags from "@/components/Tags";
import _ from "lodash";

export default {
  name: "CreateBroadcast",
  components: { Tags },
  props: {
    toggleOpenModal: Function,
    isOpen: Boolean,
  },
  data: () => ({
    is_loading: false,
    name: null,
    base_url: null,
    network: "general",
    networkList: ["wlt", "general", "gr", "gift", "customs"],
    max_links: null,
    life_days: 7,
    broadcast_type: false,
    flow: "",
    country: "",
    offer: null,
    searchCountry: "",
    searchOffer: "",
    isLoadingCountry: false,
    isLoadingOffer: false,
    broadcast_target: BROADCAST_TARGETS[0].value,
  }),
  computed: {
    ...mapState({
      filter: (state) => state.contacts.filter,
      countries: (state) => state.broadcasts.countries,
      offers: (state) => state.broadcasts.offers,
      status: (state) => state.broadcasts.status,
    }),
    BROADCAST_TARGETS: () => BROADCAST_TARGETS,
  },
  methods: {
    ...mapActions({
      onCreate: `broadcasts/${CREATE_BROADCAST}`,
      onLoadCountries: `broadcasts/${LOAD_COUNTRIES}`,
      onLoadOffers: `broadcasts/${LOAD_OFFERS}`,
    }),

    onWaitChangeCountry() {
      if (
        this.searchCountry &&
        (this.searchCountry.length >= 2 || this.searchCountry === "")
      ) {
        this.onLoadCountries(`search=${this.searchCountry}`);
      }
    },

    onWaitChangeOffer() {
      if (
        this.searchOffer &&
        (this.searchOffer.length >= 2 || this.searchOffer === "")
      ) {
        this.onLoadOffers(`search=${this.searchOffer}`);
      }
    },
    onCreateClick() {
      let data = {
        filter_conditions: this.filter || {},
        name: this.name,
        base_url: this.base_url,
        network: this.network,
        max_links: this.max_links || 0,
        country: this.country,
        offer: this.offer,
        flow: this.flow,
        broadcast_type: +this.broadcast_type,
        broadcast_target: this.broadcast_target,
        tags: this.$refs.tags.getTagsList(),
      };
      if (this.life_days) {
        data.life_days = this.life_days;
      }
      this.onCreate(data);
      this.toggleOpenModal();
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.onLoadCountries("limit=10");
        this.onLoadOffers("limit=10");
      }
    },
    status(newValue) {
      this.isLoadingCountry = newValue.load_countries === requestStatus.loading;
      this.isLoadingOffer = newValue.load_offers === requestStatus.loading;
    },
    searchCountry() {
      this.onWaitChangeCountry();
    },
    searchOffer() {
      this.onWaitChangeOffer();
    },
  },
  created() {
    this.onWaitChangeCountry = _.debounce(this.onWaitChangeCountry, 500);
    this.onWaitChangeOffer = _.debounce(this.onWaitChangeOffer, 500);
  },
};
</script>

<style scoped></style>
