var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex"},[_c('h4',{staticClass:"my-auto"},[_vm._v("Total: "+_vm._s(_vm.count))])]),_c('div',{staticClass:"col-4"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-auto"},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","title":"Import CSV file"},on:{"click":_vm.triggerImport}},[_vm._v(" Upload csv "),_c('i',{staticClass:"fas fa-file-upload",staticStyle:{"padding":"0"}}),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"accept":"text/csv","id":"attach_csv","name":"thumbnail","type":"file"},on:{"change":_vm.onUploadCSV}})])],1)])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"disable-sort":"","items":_vm.list,"hide-default-footer":"","loading":_vm.loading,"itemsPerPage":+_vm.pageFilter.limit},scopedSlots:_vm._u([{key:"header.name",fn:function({ header }){return [_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},_vm._l((_vm.headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.phone",fn:function({ item }){return [(item.phone && item.phone.number)?_c('span',[_vm._v(" "+_vm._s(item.phone.number)+" "),(item.phone.is_valid)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":item.phone.is_valid === false ? 'red' : 'orange',"small":""}},[_vm._v("mdi-close")]),(
              item.phone.has_watsapp === true ||
              item.phone.has_watsapp === false
            )?_c('v-icon',{attrs:{"color":item.phone.has_watsapp === false ? 'gray' : '#008000',"small":""}},[_vm._v("mdi-whatsapp")]):_vm._e()],1):_vm._e()]}},{key:"item.email",fn:function({ item }){return [_c('span',[_c('v-btn',{attrs:{"text":"","small":"","to":`contacts/${item.uuid}`}},[_vm._v(" "+_vm._s(item.email ? item.email.address : "without email")+" ")]),(item.email && item.email.is_valid)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):(item.email)?_c('v-icon',{attrs:{"color":item.email.is_valid === false ? 'red' : 'orange',"small":""}},[_vm._v("mdi-close")]):_vm._e()],1)]}},{key:"item.addresses",fn:function({ item }){return [(item.country)?_c('country-flag',{attrs:{"country":item.country,"size":"small"}}):_vm._e()]}},{key:"item.sources",fn:function({ item }){return [(
            item.sources &&
            item.sources.length &&
            _vm.sortSources(item.sources) &&
            _vm.sortSources(item.sources).offer
          )?_c('span',[_vm._v(" "+_vm._s(_vm.sortSources(item.sources).offer.name ? _vm.sortSources(item.sources).offer.name : _vm.sortSources(item.sources).offer.key)+" ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('pagination',{attrs:{"count":_vm.count}})]},proxy:true}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }