<template>
  <v-card>
    <v-card-text class="d-flex justify-content-between">
      <div class="row">
        <div class="col d-flex">
          <h4 class="my-auto">Total: {{ count }}</h4>
        </div>
        <div class="col-4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
        </div>
        <div class="col-auto">
          <v-btn
            color="primary"
            class="ma-2"
            @click="triggerImport"
            title="Import CSV file"
          >
            Upload csv
            <i style="padding: 0" class="fas fa-file-upload"></i>
            <input
              ref="fileInput"
              accept="text/csv"
              @change="onUploadCSV"
              id="attach_csv"
              style="display: none"
              name="thumbnail"
              type="file"
            />
          </v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        disable-sort
        :items="list"
        hide-default-footer
        :loading="loading"
        :itemsPerPage="+pageFilter.limit"
      >
        <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
        </template>

        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>

        <template v-slot:item.phone="{ item }">
          <span v-if="item.phone && item.phone.number">
            {{ item.phone.number }}
            <v-icon v-if="item.phone.is_valid" color="green" small
              >mdi-check</v-icon
            >

            <v-icon
              v-else
              :color="item.phone.is_valid === false ? 'red' : 'orange'"
              small
              >mdi-close</v-icon
            >

            <v-icon
              v-if="
                item.phone.has_watsapp === true ||
                item.phone.has_watsapp === false
              "
              :color="item.phone.has_watsapp === false ? 'gray' : '#008000'"
              small
              >mdi-whatsapp</v-icon
            >
          </span>
        </template>

        <template v-slot:item.email="{ item }">
          <span>
            <v-btn text small :to="`contacts/${item.uuid}`">
              {{ item.email ? item.email.address : "without email" }}
            </v-btn>
            <v-icon v-if="item.email && item.email.is_valid" color="green" small
              >mdi-check</v-icon
            >

            <v-icon
              v-else-if="item.email"
              :color="item.email.is_valid === false ? 'red' : 'orange'"
              small
              >mdi-close</v-icon
            >
          </span>
        </template>

        <template v-slot:item.addresses="{ item }">
          <country-flag
            v-if="item.country"
            :country="item.country"
            size="small"
          />
        </template>

        <template v-slot:item.sources="{ item }">
          <span
            v-if="
              item.sources &&
              item.sources.length &&
              sortSources(item.sources) &&
              sortSources(item.sources).offer
            "
          >
            {{
              sortSources(item.sources).offer.name
                ? sortSources(item.sources).offer.name
                : sortSources(item.sources).offer.key
            }}
          </span>
        </template>

        <template v-slot:footer>
          <pagination :count="count" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CountryFlag from "vue-country-flag";
import _ from "lodash";
import { LOAD_LIST, IMPORT_CSV } from "../../../store/modules/contacts/types";
import { renderDateTime, requestStatus } from "@/services/services";
import Pagination from "@/components/Pagination";
import { CHANGE_FILTER } from "../../../store/modules/page/types";

export default {
  name: "ContactTable",
  components: { Pagination, CountryFlag },
  data: () => ({
    headers: [
      {
        text: "Email",
        align: "start",
        value: "email",
        formatter: (x) => (x ? x?.address : null),
      },
      { text: "First Name", value: "first_name" },
      { text: "Last Name", value: "last_name" },
      { text: "Country", value: "addresses" },
      { text: "Phone", value: "phone" },
      { text: "Offer", value: "sources" },
      {
        text: "Updated at",
        value: "updated_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
    ],
    search: "",
    loading: false,
  }),
  computed: {
    ...mapState({
      errors: (state) => state.contacts.errors,
      list: (state) => state.contacts.list,
      count: (state) => state.contacts.count,
      status: (state) => state.contacts.status,
      filter: (state) => state.contacts.filter,
      needUpdate: (state) => state.contacts.needUpdate,
      pageFilter: (state) => state.page.filter,
    }),
  },
  methods: {
    ...mapActions({
      onLoadList: `contacts/${LOAD_LIST}`,
      importCSV: `contacts/${IMPORT_CSV}`,
    }),
    sortSources(sources) {
      let arr = JSON.parse(JSON.stringify(sources));
      // console.log(arr.sort((a, b) => new Date(b.date) - new Date(a.date)));
      let lastElement = arr.pop();
      return lastElement;
    },
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    onLoadData(pageFilter) {
      this.onLoadList({
        filter_conditions: this.filter,
        limit: pageFilter.limit,
        offset: pageFilter.offset,
        search: pageFilter.search,
        sort: ["-updated_at"],
      });
    },

    onWaitChange() {
      if (this.search.length >= 2 || this.search === "") {
        this.changeFilter({ search: this.search });
      }
    },
    triggerImport: function () {
      this.$refs.fileInput.click();
    },
    onUploadCSV: function (event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        let file = input.files[0];
        let data = new File([file], encodeURIComponent(file.name), {
          type: file.type,
        });
        this.importCSV(data);
      }
    },
  },
  watch: {
    status(newValue) {
      this.loading = newValue.load_list === requestStatus.loading;

      if (
        newValue.change_field === "upload_csv" &&
        newValue.upload_csv === requestStatus.success
      ) {
        this.$toaster.success("Success upload file");
      }

      if (
        newValue.change_field === "upload_csv" &&
        newValue.upload_csv !== requestStatus.loading
      ) {
        this.$refs.fileInput.value = "";
      }
    },
    needUpdate(newValue) {
      if (newValue) {
        this.onLoadData(this.pageFilter);
      }
    },
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
    search() {
      this.onWaitChange();
    },
  },
  created() {
    this.onLoadData(this.pageFilter);
    this.onWaitChange = _.debounce(this.onWaitChange, 500);
  },
};
</script>

<style scoped></style>
