/* eslint-disable */
import $ from 'jquery';
import 'jQuery-QueryBuilder';
import 'bootstrap-datepicker';
import 'bootstrap-datetime-picker';
import 'selectize';
import {IRule} from './interfaces';
import * as BroadcastsAPI from "@/store/modules/broadcasts/api";
import {requestStatus} from "@/services/services";

export const setFilterForBuilder = (params: any) => {
    let filter: any = [];
    params.forEach((item: any) => {
        let data = {
            name: item.id,
            operators: item.operators,
            label: item.label,
            field_type: item.type
        };
        filter.push(data)
    });
    return filter;
};

const SELECTIZE_SETTINGS = {
    Country: {request: BroadcastsAPI.loadCountries, valueField: 'code'},
    Broadcasts: {request: BroadcastsAPI.loadList, valueField: 'uuid'},
    'Broadcasts groups': {request: BroadcastsAPI.loadBroadcastGroups, valueField: 'id'},
    'Broadcasts has clicks': {request: BroadcastsAPI.loadList, valueField: 'uuid'},
    'Broadcasts has no clicks': {request: BroadcastsAPI.loadList, valueField: 'uuid'},
    'Broadcasts has leads': {request: BroadcastsAPI.loadList, valueField: 'uuid'},
    'Broadcasts offer has no leads': {request: BroadcastsAPI.loadOffers, valueField: 'uuid'},
    'Source Offer': {request: BroadcastsAPI.loadOffers, valueField: 'uuid'},
    'Offer has leads': {request: BroadcastsAPI.loadOffers, valueField: 'uuid'}
}

let ruleChangedFields: { [key: string]: string } = {};

export class Builder {
    private queryBuilder: any;

    constructor(id: string, _rules: IRule[], onChangeCallback: () => void, defaultValue?: IRule[]) {
        this.queryBuilder = $(id);

        this.queryBuilder.on('afterUpdateRuleValue.queryBuilder', function (e: any, rule: any) {
            if (rule.filter.plugin === 'datepicker') {
                rule.$el.find('.rule-value-container input').datepicker('update');
            }
            onChangeCallback();
        });

        this.queryBuilder.on('afterCreateRuleInput.queryBuilder', function (e: any, rule: any) {
            if (rule.filter.plugin === 'datetimepicker') {
                let $input = rule.$el.find('.rule-value-container [name*=_value_]');
                $input.attr('autocomplete', `off`);

                $input.on('dp.change', function () {
                    $input.trigger('change');
                });
            }
        });

        this.queryBuilder.on('afterCreateRuleInput.queryBuilder', function (e: any, rule: any) {
            if (rule.filter.plugin == 'selectize') {
                rule.$el.find('.rule-value-container').css('min-width', '200px')
                    .find('.selectize-control').removeClass('form-control');
            }
        });

        const formatted_rule = [..._rules].map((item) => {
            let config: any = {};
            let rule = {...item}

            if (rule.type === 'uuid') {
                ruleChangedFields[rule.id] = rule.type;
                rule.type = 'string';
            }

            if (rule.plugin === 'selectize') {
                config = {
                    plugin_config: {
                        valueField: SELECTIZE_SETTINGS[rule.label as 'Country' | 'Broadcasts' | 'Broadcasts groups'].valueField,
                        labelField: 'name',
                        searchField: 'name',
                        maxItems: rule.operators?.includes('in') ? 100 : 1,
                        plugins: ['remove_button'],
                        load: function (query: any, callback: any) {
                            if (!query.length) return callback();
                            SELECTIZE_SETTINGS[rule.label as 'Country' | 'Broadcasts' | 'Broadcasts groups'].request(`search=${query}`)
                                .then(function ({response, status}: any) {
                                    if (status === requestStatus.success) {
                                        callback(response.data?.results);
                                    }
                                })
                        },
                        onInitialize: function (this: any) {
                            setTimeout(() => {
                                let value: string = this.$input[0].value;
                                const filter = `${value ? SELECTIZE_SETTINGS[rule.label as 'Country' | 'Broadcasts' | 'Broadcasts groups'].valueField + '=' + value : 'limit=10'}`;
                                SELECTIZE_SETTINGS[rule.label as 'Country' | 'Broadcasts' | 'Broadcasts groups'].request(filter)
                                    .then(({response, status}: any) => {
                                        if (status === requestStatus.success) {
                                            response.data?.results.forEach((item: any) => {
                                                this.addOption(item);
                                            });
                                            if(value && rule.operators?.includes('in')){
                                                this.setValue(value.split(','));
                                            } else {
                                                value && this.setValue(value);
                                            }
                                            // response.data && this.setValue(response.data?.results);
                                        }
                                    })
                            }, 0)
                        }
                    },
                    valueSetter: function (rule: any, value: any) {
                        if(Array.isArray(value)){
                            rule.$el.find('.rule-value-container input')[0].value = value.join(',');
                        } else{
                            rule.$el.find('.rule-value-container input')[0].value = value;
                        }
                    },
                    input: null
                }
            }

            return {...rule, ...config};
        })

        
        // console.log($.fn.queryBuilder.constructor.DEFAULTS.operators);

        this.queryBuilder.queryBuilder({
            filters: formatted_rule,
            rules: defaultValue,
            operators: $.fn.queryBuilder.constructor.DEFAULTS.operators.concat([
                {type: 'is_valid', nb_inputs: 0, apply_to: ['string']},
                {type: 'is_not_valid', nb_inputs: 0, apply_to: ['string']},
                {type: 'exists', nb_inputs: 0, apply_to: ['string']},
                {type: 'not_exists', nb_inputs: 0, apply_to: ['string']},
            ])
        })
    }

    initializeConditions = (data: any) => {
        this.queryBuilder.conditionsBuilder(data);
    };

    changeField = (rules: any) => {
        return rules.map((rule: any) => {
            if (rule.rules) {
                rule.rules = this.changeField(rule.rules);
            }
            if (ruleChangedFields[rule.id]) {
                rule.type = ruleChangedFields[rule.id];
            }
            if(rule.operator === "in" || rule.operator === "not_in"){
                rule.value = rule.value.split(',');
            }
            return rule;
        })

    }


    getRules = () => {
        let rules = this.queryBuilder.queryBuilder('getRules');
        rules.rules = this.changeField(rules.rules);

        return rules;
    }


    setRules = (rules: any) => {
        this.queryBuilder.queryBuilder('setRules', rules);

    }

    resetRules = () => {
        this.queryBuilder.queryBuilder('reset');

    }
}