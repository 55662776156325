<template>
  <v-container  :fluid="true">
    <contact-filter :toggleOpenCreateBroadcast="toggleOpenCreateBroadcast"/>
    <contact-table/>
    <create-broadcast :is-open="isShowCreateBroadcast" :toggle-open-modal="toggleOpenCreateBroadcast"/>
  </v-container>
</template>

<script>
import CreateBroadcast from "../Broadcasts/components/CreateBroadcast";
import ContactTable from "./components/Table";
import ContactFilter from "./components/Filter";
import {requestStatus} from "@/services/services";
import {mapActions, mapMutations, mapState} from "vuex";
import {ADD_HEADER_SETTINGS} from "../../store/modules/layout/types";
import {SET_DEFAULT_STATE} from "../../store/modules/contacts/types";

export default {
  name: "Contacts",
  components: {CreateBroadcast, ContactTable, ContactFilter},
  data: () => ({
    isShowCreateBroadcast: false
  }),
  computed: {
    ...mapState({
      statusBroadcasts: state => state.broadcasts.status
    })
  },
  methods: {
    ...mapActions({
      addSettings: `layout/${ADD_HEADER_SETTINGS}`,
    }),
    ...mapMutations({
      onClearData: `contacts/${SET_DEFAULT_STATE}`,
    }),
    toggleOpenCreateBroadcast() {
      this.isShowCreateBroadcast = !this.isShowCreateBroadcast
    },
  },
  watch: {
    statusBroadcasts(newValue) {
      if (
          newValue.change_field === "create" &&
          newValue.create === requestStatus.success
      ) {
        this.$toaster.success("Success create broadcast");
      }
    }
  },

  beforeMount() {
    this.addSettings({
      pageName: 'Contacts',
      actions: []
    })
  },
  beforeRouteLeave(to, from, next) {
    this.onClearData();
    next();
  }
}
</script>

<style scoped>

</style>